<template>
  <div class="admin-add-sales-import">
    <error :err.sync="error" />
    <template v-if="loading">
      <b-progress
        :value="uploadProgress"
        show-progress
        animated
        height="1.6rem"
      ></b-progress>
      <h3 class="text-center mt-5">
        {{ $t("uploading") }}
      </h3>
    </template>
    <form-generator
      :elements="elements"
      :data="salesImport"
      :handleUpdate="({ key }, v) => (salesImport[key] = v)"
      v-if="render && !loading"
    />
  </div>
</template>

<script>
import saleImportSchema from "@/validation-schemas/sale-import";
import FormGenerator from "@/components/form-generator.vue";
import { mapState } from "vuex";

function initialState() {
  return {
    salesImport: {
      filename: null,
      file: null,
      sales_channel: null,
      sales_channel_id: null,
      processor_type: null,
      notes: null
    },
    loading: false,
    error: null,
    render: true,
    clientToServerProgress: 0,
    serverToS3Progress: 0
  };
}

export default {
  components: { FormGenerator },
  name: "admin-add-sales-import",
  data() {
    return initialState();
  },
  mounted() {
    this.$store.dispatch("ancillaries/getSalesChannels", {
      all: true
    });
    this.$store.dispatch("saleImports/getProcessorTypes");

    // Pusher progress
    this.listenForUploadProgress();
  },
  computed: {
    ...mapState("ancillaries", ["allSalesChannels"]),
    ...mapState("saleImports", ["processorTypes"]),
    elements() {
      return [
        {
          type: "dropzone",
          events: {
            input: files => {
              if (files.length) {
                this.salesImport.file = files[0];
                this.salesImport.filename = files[0].name;
              } else {
                this.salesImport.filename = null;
                this.salesImport.file = null;
              }
            }
          }
        },
        {
          key: "sales_channel",
          label: "sales-channel",
          type: "multiselect",
          attrs: {
            options: this.allSalesChannels,
            trackBy: "id",
            label: "name",
            multiple: false,
            loading: !this.allSalesChannels.length
          }
        },
        {
          key: "processor_type",
          label: "processor",
          type: "select",
          attrs: {
            options: [
              {
                text: this.$t("select-processor"),
                value: null
              }
            ].concat(
              this.processorTypes.map(p => ({
                text: p.name,
                value: p.code
              }))
            )
          }
        },
        {
          key: "notes",
          label: "notes",
          type: "text",
          className: "mb-5"
        },
        {
          text: "import",
          type: "button",
          className: "float-right mt-5 mt-sm-0",
          attrs: {
            loading: this.loading,
            block: this.isSmallScreen
          },
          events: {
            click: this.add
          }
        }
      ];
    },
    uploadProgress() {
      return this.clientToServerProgress + this.serverToS3Progress;
    }
  },
  methods: {
    listenForUploadProgress() {
      const channel = this.$pusher.subscribe("upload-progress");
      channel.bind("import-file-upload-progress", ({ progressData: data }) => {
        this.serverToS3Progress = ((data.progress / data.total) * 100) / 2 || 0;
      });
    },
    async add() {
      this.error = null;
      const { salesImport } = this;
      const validation = saleImportSchema.validate(
        salesImport,
        this.joiOptions
      );
      if (validation.error) {
        this.error = validation.error.message;
      } else {
        this.loading = true;
        try {
          await this.$store.dispatch("saleImports/create", {
            salesImport,
            onUploadProgress: e => {
              this.clientToServerProgress = ((e.loaded / e.total) * 100) / 2;
            }
          });

          // Reset data
          Object.assign(this.$data, initialState());
          this.$emit("added");
        } catch (err) {
          console.error(err);
        }
        this.loading = false;
      }
    }
  },
  watch: {
    "salesImport.sales_channel"(channel) {
      this.salesImport.sales_channel_id = channel ? channel.id : null;
    }
  }
};
</script>

<style></style>
